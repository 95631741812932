.json-displayer {
  width: 100%;
  height: fit-content;
  padding: 10px;
}

.json-displayer-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.json-displayer-content {
  width: 100%;
  height: fit-content;
}

.json-displayer-list-item {
  width: 100%;
  height: fit-content;
  display: flex;
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.json-displayer-list-dot {
  width: 10px;
  margin: 0 10px 0 20px;
  height: 100%;
}

.json-displayer-list-item-text {
  display: inline-block;
}

.json-displayer-text {
  width: 100%;
  height: fit-content;
  font-size: 0.8rem;
  margin: 5px 0;
  line-height: 1.2rem;
}

.subtitle-1 {
  font-size: 1.8rem;
  font-weight: 800;
  margin: 20px 0 16px;
}

.subtitle-2 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 18px 0 14px;
}

.subtitle-3 {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 14px 0 12px;
}

.subtitle-4 {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 16px 0 10px;
}

.subtitle-5 {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 12px 0 8px;
}

.subtitle-6 {
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0 6px;
}

.json-displayer-button {
  width: fit-content;
  height: fit-content;
  padding: 10px 15px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  color: #333;
  border-radius: 5px;
  margin: 20px 0;
  font-size: 0.8rem;
}

.json-displayer-button:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}