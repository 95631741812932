.getterms-document-embed {
  color: #fff;
  padding: 10px 30px;
}

.getterms-document-embed a {
  color: #80abff;
}

.getterms-document-embed a:visited {
  color: #7798d1;
}