.navigation-bar-container {
  width: 100%;
  min-width: 900px;
  height: 80px;
  padding-bottom: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  /* background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)); */
  
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.navigation-bar-logo {
  width: 100px;
  height: 60px;
  margin: 5px 20px 5px 30px;
  background-image: url('/src/assets/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.navigation-bar-item {
  width: fit-content;
  height: 50px;
  margin: auto 18px;
  padding: 0 2px;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: default;
  transition: color 0.5s ease;
}


.navigation-bar-item:hover {
  color: #7490ee;
  font-weight: 500;
  border-bottom: 2px solid #7490ee;
}

.navigation-bar-item.active {
  color: #7192ff;
  font-weight: 500;
  border-bottom: 2px solid #527afd;
}

.login-button:hover {
  color: #bdcaf7;
  /* border: 1px solid #5d7fee; */
  box-shadow: 0 0 5px 0 #bdcaf7;
  border: 1px solid #bdcaf7;
  text-decoration: none;
}

.login-button {
  font-size: 0.8rem;
  box-sizing: border-box;
  border-radius: 3px;
  height: fit-content;
  padding: 5px 20px;
  border: 1px solid #ffffff;
}

/* .navigation-bar-item.right-side:hover {
    color: #1e8de6;
} */


@media (max-width: 900px) {
  .navigation-bar-container {
    height: 60px;
    min-width: 0;
    backdrop-filter: blur(10px);
  }

  .navigation-bar-logo {
    width: 100px;
    height: 100%;
    margin: 0px 10px;
  }

  .navigation-bar-item {
    font-size: 0.8rem;
    margin: auto 5px;
    height: 40px;
  }

  .login-button {
    font-size: 0.7rem;
    padding: 5px 10px;
    position: relative;
    margin: 0 20px 0 5px !important;
    bottom: -15px;
    height: fit-content;
  }
}