.cookies-agreement {
  width: 400px;
  height: fit-content;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(80, 80, 80, 0.5);
  backdrop-filter: blur(10px);
  padding: 20px;
  box-sizing: border-box;
  display: none;
  z-index: 9999;
}

.cookies-agreement-content {
  width: fit-content;
  height: fit-content;
}

.cookies-agreement-content-text {
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 0.8rem;
}

@media (max-width: 900px) {
  .cookies-agreement {
    width: 95%;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}