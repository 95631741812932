.alert-container {
    position: fixed;
    left: 50%;
    top: -50px;
    opacity: 0;
    transform: translate(-50%, 0); /* Adjust for the element's own width */
    width: fit-content;
    min-width: 150px;
    height: 40px;
    line-height: 38px;
    z-index: 9999 !important;
    padding: 0 20px;
    border-radius: 5px;
    text-align: center;
    transition: opacity 0.2s ease, top 0.2s ease, width 1s ease;
    font-size: 0.75em;
    font-weight: 500;
    color: #fff;
    backdrop-filter: blur(10px);
}

.alert-container.info {
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
}

.alert-container.error {
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
}

.alert-container.success {
    background-color: rgba(0, 0, 0, 0.4);
    /* border: 1px solid #7399bf; */
    color: #b6dbff;
    border: 1px solid #b6dbff;
}

.alert-container.show {
    top: 12px;
    opacity: 1;
}

.alert-container.hide {
    top: -50px;
    opacity: 0;
}