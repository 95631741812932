/* src/pages/AuthenticationPage/AuthenticationPage.css */
.authentication-page {
    height: 100%;
    width: 100%;
}

.authentication-container {
    min-width: 450px;
    width: 450px;
    margin: auto;
    padding: 20px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.input-group {
    display: flex;
    align-items: center;
    /* margin-bottom: 10px; */
}

.input-label {
    margin: 7px 10px;
}

.authentication-input {
    flex-grow: 1;
    /* Existing styles for authentication-input */
}

.logo-box {
    width: 140px;
    height: 90px;
    margin: auto;
    background-image: url('/src/assets/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.register-link-container {
    margin: 5px 0;
    font-size: 0.8rem;
    cursor: default;
    line-height: 1.2rem;
}

.register-link {
    color: #7fb5fb;
    text-decoration: underline;
}

.register-link:hover {
    color: #4288e2;
    text-decoration: none;
}

.divider {
    margin: 20px 60px;
    position: relative;
    border-top: 1px solid #ccc;
}

.auth-input-box {
    width: 70% !important;
    max-width: 350px !important;
    margin: 0 50px 20px !important;
}

.auth-button {
    width: 70% !important;
    max-width: 350px !important;
    margin: 10px 50px 10px !important;
}