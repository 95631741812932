/* src/pages/IntroPage/IntroPage.css */

.intro-page-container {
    width: 100%;
    min-width: 900px;
    height: 100vh;
    position: fixed;
    /* background-image: url('/src/assets/images/intro-bg.jpg'); */
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    padding: 0 30px;
    box-sizing: border-box;
}

.intro-page-content {
    position: relative;
    flex: 1;
    display: flex;
    padding: 80px 0;
    box-sizing: border-box;
    /* margin: 0 20vw; */
    flex-direction: row;
}

.intro-page-content-container {
    flex: 2;
    box-sizing: border-box;
    position: relative;
}

.slogan-video-container {
    flex: 3;
    padding: 0 clamp(20px, 5vw, 50px) 0 clamp(0px, 10vw, 100px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.slogan-pad {
    flex: 1;
}

.slogan-video {
    width: 100%;
    overflow: hidden;
}

.response-container {
    flex: 1;
    padding: 20px 20px 0;
}

.response-container .response-text {
    width: fit-content;
    transition: all 0.2s;
    height: 1.5rem;
    line-height: 1.3rem;
    color: #777;
}

.follow-us-container {
    width: 100%;
    height: fit-content;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.follow-us-title {
    width: 100%;
    text-align: left;
    line-height: 1.5rem;
}

.follow-us-form {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}

.follow-us-button {
    margin-left: 1rem !important;
}

/* mobile */
@media (max-width: 900px) {
    .intro-page-container {
        width: 100vw;
        height: fit-content;
        padding: 0;
        display: block;
        position: absolute;
    }

    .intro-page-content {
        padding: 50px 0;
        flex-direction: column;
        min-height: 100vh;
        position: relative;
    }

    .intro-page-content-container {
        width: 100% !important;
        height: fit-content;
        position: relative;
    }

    .slogan-video-container {
        padding: 0;
        width: 100%;
    }

    .slogan-video {
        width: 100%;
    }

    /* .slogan-pad {
        height: 10vh;
        width: 100%;
        flex: none;
    } */

    .intro-page-content-container:last-child {
        width: 100%;
        padding: 0 20px;
        flex: 2;
        height: fit-content;
    }

    .follow-us-container {
        width: 100%;
        height: fit-content;
        position: relative;
        top: 0;
        transform: none;
    }

    .follow-us-form {
        flex-direction: column;
    }

    .follow-us-button {
        margin-left: 0 !important;
        margin-top: 1rem !important;
    }
}