.career-drawer {
  width: 100%;
  height: fit-content;
  padding: 10px 20px 0;
  box-sizing: border-box;
  border: 2px solid #f0f0f0;
  border-radius: 5px;
  position: relative;
  background-color: rgba(100, 100, 100, 0.3);
  backdrop-filter: blur(20px);
  margin-bottom: 30px;
}

.career-drawer-button {
  text-align: center;
  width: 100%;
  height: fit-content;
}

.career-drawer-markdown a {
  color: #5c98eb;
  text-decoration: none;
  vertical-align: top;
}

.career-drawer-markdown a:hover {
  text-decoration: underline !important;
}