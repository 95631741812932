.footer-bar {
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  padding: 10px 200px 0;
  box-sizing: border-box;
}

.footer-bar-content {
  min-width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Vertically align children to the middle */
}

.footer-bar-content-item {
  flex: 1;
  height: fit-content;
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.footer-bar-content-item-title {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  margin-right: 5px;
  cursor: default;
}

.footer-bar-content-item-container {
  width: fit-content;
  display: inline-block;
  flex: 1;
  margin: 0 25px 0 0;
}

.footer-bar-content-item-text {
  font-size: 12px;
  color: #bbb;
  font-weight: 500;
  cursor: pointer;
}

.footer-bar-content-item-text a, .footer-bar-link {
  color: #4088ee;
  text-decoration: none;
  vertical-align: middle;
}

.footer-bar-inline-icon {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 5px 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.footer-bar-inline-icon.instagram-icon {
  background-image: url('/src/assets/icons/instagram-logo.png');
}

.footer-bar-inline-icon.github-icon {
  background-image: url('/src/assets/icons/github-logo.png');
}

.footer-bar-inline-icon.twitter-icon {
  background-image: url('/src/assets/icons/x-logo.png');
}

.footer-bar-inline-icon.linkedin-icon {
  background-image: url('/src/assets/icons/linkedin-logo.png');
}

@media (max-width: 900px) {
  .footer-bar {
    position: relative;
    padding: 10px;
    bottom: 0;
  }

  .footer-bar-content {
    min-width: 100%;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .footer-bar-content-item {
    flex: 1 0 auto;
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    vertical-align: top;
    align-items: center;
  }

  .footer-bar-content-item-container {
    width: fit-content;
    display: inline-block;
    flex: 1;
    margin: 0 0;
    min-width: 100px;
    flex-wrap: wrap;
  }
  
  .footer-bar-content-item-title {
    width: fit-content;
    margin: 0;
  }

  .footer-bar-content-item-text {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}