.background-video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.background-video .background-video-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.background-video-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}

.background-video video::-webkit-media-controls {
  display: none;
}

.background-video video::-webkit-media-controls-enclosure {
  display: none;
}