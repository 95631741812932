/* src/pages/NotFoundPage/NotFoundPage.css */

.not-found-page-container {
    width: 100%;
    margin: auto;
    padding: 20px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.not-found-page-content {
    font-size: 1rem;
    font-weight: 400;
    color: #999;
}

.not-found-show-logo {
    width: 100px;
    height: 100px;
    margin: auto;
    background-image: url('/src/assets/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.not-found-text {
    height: 30px;
    line-height: 30px;
    margin: 10px 0;
}