.career-page-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
}

.career-page-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  min-width: 600px;
  height: fit-content;
  padding: 20px;
}

.career-page-content {
  padding: 20px 0 80px;
  box-sizing: border-box;
  height: 70vh;
  overflow-y: scroll;
  /* remove scroll bar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.career-page-content-title {
  font-size: 2em;
  font-weight: 500;
  color: #bbb;
  margin-bottom: 20px;
  cursor: default;
}

@media (max-width: 900px) {
  .career-page-container {
    height: fit-content;
    width: 100vw;
    height: 100vh;
    top: 0;
    position: relative;
  }

  .career-page-content-container {
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 0 40px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    transform: none;
    margin: 10vh 0 5vh;
  }

  .career-page-content {
    height: fit-content;
    padding: 20px 0;
  }
}