.about-page-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
}

.about-page-content-container {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  min-width: 600px;
  height: fit-content;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.about-page-content {
  flex: 1;
  height: 100%;
}

.about-page-content-title {
  font-size: 2em;
  font-weight: 500;
  color: #bbb;
  margin-bottom: 40px;
  cursor: default;
}

.about-page-content-description {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.8em;
  color: #eee;
  cursor: default;
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .about-page-container {
    height: fit-content;
    width: 100vw;
    height: fit-content;
    top: 0;
    position: relative;
  }

  .about-page-content-container {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    min-width: 0;
    padding: 0 40px;
    box-sizing: border-box;
    flex-direction: column;
    margin: 25vh 0;
  }

  .about-page-content-title {
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  .about-page-content-description {
    font-size: 0.9em;
    margin-bottom: 10px;
    text-align: justify;
  }
}