/* src/pages/LoginPage/LoginPage.css */

.login-redirect-page-container {
    min-width: 600px;
    width: 600px;
    margin: auto;
    padding: 20px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.login-logo {
    width: 100px;
    height: 100px;
    margin: auto;
    background-image: url('/src/assets/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.login-redirection-text {
    margin: 20px;
    text-align: center;
    font-size: 0.8em;
}