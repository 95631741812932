.third-party-auth-title {
    text-align: center;
    font-size: 12px;
    color: #bbb;
    font-weight: 500;
    margin: 20px 0;
}

.third-party-auth-container {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0 60px;
    box-sizing: border-box;
}

.third-party-auth-item {
    width: 40px;
    height: 40px;
    margin: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: default;
    border-radius: 50%;
}

.third-party-auth-item.disabled {
    opacity: 0.2;
    cursor: not-allowed;
}

.third-party-auth-item.google {
    background-image: url('/src/assets/icons/google-logo.jpg');
}

.third-party-auth-item.apple {
    background-color: #fff;
    background-image: url('/src/assets/icons/apple-logo.png');
    background-size: 90% !important;
    background-position: 37% 30%;
}

.third-party-auth-item.facebook {
    background-image: url('/src/assets/icons/facebook-logo.png');
}

.third-party-auth-item.x {
    background-image: url('/src/assets/icons/x-logo.png');
    background-size: 80% !important;
    background-color: #000;
}
